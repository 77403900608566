/**
* App CSS
*/

body, html {
    background: #FAFAFA !important;
}

input {
    outline: none !important;
}

.MuiInputLabel-root {
    text-transform: capitalize !important;
    font-size: 0.9em !important;
    line-height: 1.75em !important;
}

.navbar-dark .navbar-nav .nav-link {
    font-size: 18px !important;
    color: #FFF !important;
}