.player-wrapper {
    position: relative;
    height: auto;
    overflow: hidden;
}

.instaPlayer {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 Aspect ratio Fix */
	height: 0;
    background: #000;
}

.instaPlayer .loader {
    width: 100%;
    height: 100%;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
}

.instaPlayer .loader.isLoading .loading {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #0d6efd;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.instaPlayer .loader.isLoaded {
    display: none;
}